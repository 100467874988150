import { Layout } from "antd";
import React from "react";
import FooterSection from "../components/FooterSection";
import HomeContent from "../components/HomeContent";
import Nav from "../components/Nav";

const { Content, Footer, Header } = Layout;

const Home = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Header>
        <Nav />
      </Header>
      <Content>
        <HomeContent />
      </Content>
      <Footer>
        <FooterSection />
      </Footer>
    </Layout>
  );
};

export default Home;

import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';

import "antd/dist/antd.css";
import "./scss/main.scss";
import Timer from './components/Timer/index';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Timer} />
    </Switch>
  );
}

export default App;

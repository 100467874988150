import React, {useEffect, useState} from 'react';
import Logo from '../images/logo.png';
import Countdown from "react-countdown";
const renderer = ({ days,hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
    //   return (
    //     <span className = "liveTimer">
    //       {days < 10 ? "0" + days: days}  : {hours < 10 ? "0" + hours: hours} : {minutes < 10 ? "0" + minutes: minutes} : {seconds < 10 ? "0" + seconds : seconds}
    //     </span>
    //   );
    return (
        <ul class="countdown">
			<li>
				<div class="num">{days < 10 ? "0" + days: days}</div>
				<div class="txt">days</div>
			</li>
			<li>
				<div class="num">{hours < 10 ? "0" + hours: hours}</div>
				<div class="txt">hours</div>
			</li>
			<li>
				<div class="num">{minutes < 10 ? "0" + minutes: minutes}</div>
				<div class="txt">mins</div>
			</li>
			<li>
				<div class="num">{seconds < 10 ? "0" + seconds : seconds}</div>
				<div class="txt">sec</div>
			</li>
		</ul>
    )
    }
  };
  const Completionist = () => <span></span>;
const Timer = (props) => {
    
    return (
        <div className = "timePage">
            <div className = "logoPart">
                <div className = "logoTitle">
                    <h1/> Farmley.in
                </div>
            </div>
            <div className = "counter">
            <h1>This Domain is up for SALE</h1>
            <h2>Contact us : <a onClick={() => window.location = 'mailto:hatodi0945@gmail.com'}>hatodi0945@gmail.com</a> </h2>
            <Countdown date={1635608093973 + 345600000} renderer={renderer} />
            </div>
                    
        </div>
    )
}
export default Timer;